body {
  margin: 0;
  font-family: GT-America, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "GT-America";
  src: local("GT-America"),
    url(./fonts/GT-America-Standard-Light.woff2) format("woff2");
}

@font-face {
  font-family: "GT-America";
  font-weight: 600;
  src: local("GT-America"),
    url(./fonts/GT-America-Standard-Medium.woff2) format("woff2");
}

@font-face {
  font-family: "Neue Helvetica";
  font-style: italic;
  font-weight: normal;
  src: local("NeueHelveticaItalic"),
    url("./fonts/NeueHelveticaItalic.woff2") format("woff2"),
    local("NeueHelveticaItalic"),
    url("./fonts/NeueHelveticaItalic.woff") format("woff");
}
@font-face {
  font-family: "Neue Helvetica";
  font-style: normal;
  font-weight: bold;
  src: local("NeueHelveticaBold"),
    url("./fonts/NeueHelveticaBold.woff2") format("woff2"),
    local("NeueHelveticaBold"),
    url("./fonts/NeueHelveticaBold.woff") format("woff");
}

@font-face {
  font-family: "Neue Helvetica";
  font-style: normal;
  font-weight: 700;
  src: local("NeueHelveticaBold"),
    url("./fonts/NeueHelveticaBold.woff2") format("woff2"),
    local("NeueHelveticaBold"),
    url("./fonts/NeueHelveticaBold.woff") format("woff");
}

@font-face {
  font-family: "Neue Helvetica";
  font-style: normal;
  font-weight: 500;
  src: local("NeueHelvetica"),
    url("./fonts/NeueHelvetica.woff2") format("woff2"), local("NeueHelvetica"),
    url("./fonts/NeueHelvetica.woff") format("woff");
}

@font-face {
  font-family: "Neue Helvetica";
  font-style: normal;
  font-weight: 400;
  src: local("NeueHelveticaLight"),
    url("./fonts/NeueHelveticaLight.woff2") format("woff2"), local("NeueHelveticaLight"),
    url("./fonts/NeueHelveticaLight.woff") format("woff");
}

/* LB Member Portal Adobe Fonts project under steven@liteboxer.com */
@import url("https://use.typekit.net/pdg7bny.css");