.backgroundGrad {
  background-image: linear-gradient(
    #f3f4f6 0%,
    #6b727f 50%,
    #f3f4f6 100%,
  );
}

.breadcrumbBorder {
  bottom: 4px;
}
