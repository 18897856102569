.backgroundGrad {
  background-image: linear-gradient(
    #f3f4f6 0%,
    #6b727f 50%,
    #f3f4f6 100%,
  );
}

.container {
  font-family: "Neue Helvetica";
  font-weight: 400;
  letter-spacing: 0.025rem;
}

.container label {
  color: white; 
}

.container h2, .container button {
  font-family: nimbus-sans-extended, sans-serif;
}

.container button {
  font-weight: 700;
  font-size: 16px;
}

.container h2 {
  font-size: 35px;
}

.container h2, .container h4 {
  text-shadow: 2px 2px 4px rgba(0,0,0,.75);
}

/* @media only screen and (min-aspect-ratio: 1) {
  .container h2, .container h4 {
    text-shadow: 2px 2px 2px rgba(0,0,0,1);
  }
} */

.container figure {
  background-color: #404140;
}