/*
Styles that we override here with :global() come from:
https://github.com/leandrowd/react-responsive-carousel/blob/d78aa78401a684554f9b734c4eb98c6e3927a6ba/src/components/_carousel.scss
*/

.carousel :global(.control-dots) {
  margin-bottom: 0;
}

.carousel :global(.control-dots) :global(.dot) {
  box-shadow: none;
  background-color: gray;
  margin: 0 4px;
}

.carousel :global(.control-dots) :global(.dot.selected) {
  background-color: #01aa1b;
}