.membershipContainer {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.membershipTag {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #01aa1b;
  display: inline-block;
  transform: translateX(50%) rotate(-45deg) translateX(-50%) rotate(90deg);
  transform-origin: center top;
  box-shadow: 0px -50px 0px 50px #01aa1b;
  color: white;
  border: none;
  padding: 0 10px 5px;
}
